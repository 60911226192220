import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { CachePolicies, Provider } from 'use-http';
import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import { HelmetProvider } from 'react-helmet-async';

if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.querySelector('html')?.classList.add('dark');
} else {
  document.querySelector('html')?.classList.remove('dark');
}

ReactDOM.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider
        url={process.env.REACT_APP_API_URL}
        options={{ cachePolicy: CachePolicies.NO_CACHE }}
      >
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </I18nextProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
