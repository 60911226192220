import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Alert.module.scss';

interface Props {
  variant?: 'danger' | 'info' | 'success';
}

const Alert: FC<Props> = ({ children, variant = 'info' }) => {
  const { t } = useTranslation();
  const titleKey = useMemo(() => {
    switch (variant) {
      case 'success':
        return 'alert.success';

      case 'danger':
        return 'alert.error';

      default:
        return 'alert.info';
    }
  }, [variant]);

  return (
    <div className={classNames(styles['alert'], styles[variant])} role="alert">
      <strong className="mr-2">{t(titleKey)}</strong>
      {children}
    </div>
  );
};

export type { Props as AlertProps };
export default Alert;
