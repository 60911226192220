import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next/';
import { useLocation } from 'react-router-dom';
import useFetch from 'use-http';
import Alert from '../../components/atoms/Alert';

const VerifyEmail: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { error, post, response } = useFetch();
  const [token, setToken] = useState<string>();
  const [isTokenValid, setTokenValidity] = useState<boolean>();
  const [isReady, setReady] = useState(false);

  const getErrorMessage = useCallback(() => {
    if (!token) {
      return t('errors.noToken');
    }

    switch (response.status) {
      case 404:
        return t('errors.tokenNotFound');

      default:
        return t('errors.unknown', { code: response.status || 1 });
    }
  }, [response, t, token]);

  useEffect(() => {
    let isMounted = true;
    const queryParams = new URLSearchParams(search);

    if (!queryParams.has('token')) {
      setReady(true);
      return;
    }

    setToken(queryParams.get('token') as string);

    const validateToken = async () => {
      await post('/users/verify', {
        token: queryParams.get('token') as string,
      });

      if (isMounted) {
        setTokenValidity(response.ok);
        setReady(true);
      }
    };

    validateToken();

    return () => {
      isMounted = false;
    };
  }, [post, response, search]);

  return (
    <>
      <Helmet>
        <title>
          {`${t('resetPassword.title')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>

      <div className="container center">
        <div className="main-box main-box-small">
          <h1 className="text-center">{t('verifyEmail.title')}</h1>
          {isReady && (
            <div className="w-full md:w-1/2 xl:w-1/3 mx-auto">
              {isTokenValid && (
                <Alert variant="success">{t('verifyEmail.success')}</Alert>
              )}
              {(!token || error) && (
                <Alert variant="danger">{getErrorMessage()}</Alert>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
