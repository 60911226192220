import { FC } from 'react';

const Home: FC = () => (
  <div className="container center">
    <h1 className="title" data-testid="home-title">
      AIFA Smart
    </h1>
  </div>
);

export default Home;
