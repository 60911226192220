import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  autofocus?: boolean;
  id: HTMLInputElement['id'];
  label: string;
  name: HTMLInputElement['name'];
  onBlur: FocusEventHandler;
  onChange: ChangeEventHandler;
  placeholder?: string;
  required?: HTMLInputElement['required'];
  type?: HTMLInputElement['type'];
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      autofocus = false,
      id,
      label,
      name,
      onBlur,
      onChange,
      placeholder,
      required = false,
      type = 'text',
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [inputType, setInputType] = useState<HTMLInputElement['type']>(type);
    const toggleVisibility = useCallback(() => {
      setInputType(inputType === 'text' ? 'password' : 'text');
    }, [inputType]);

    return (
      <section className="form-group">
        <label htmlFor={id} className="form-label">
          {label}
        </label>
        <input
          autoFocus={autofocus}
          className="form-input"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder || label}
          ref={ref}
          required={required}
          type={inputType}
        />

        {type === 'password' && (
          <button
            aria-label={t('forms.showHideLabel')}
            className="ml-auto"
            onClick={toggleVisibility}
            type="button"
          >
            {t(inputType === 'text' ? 'forms.hide' : 'forms.show')}
          </button>
        )}
      </section>
    );
  }
);

Input.displayName = 'Input';

export type { Props as InputProps };
export default Input;
