import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Alert from '../../components/atoms/Alert';
import Input from '../../components/atoms/Input';

interface FormInputs {
  email: string;
}

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormInputs>();
  const { error, loading, post, response } = useFetch();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const resetPassword: SubmitHandler<FormInputs> = async (data) => {
    await post('/password/reset', data);

    setSuccess(response.ok);
    if (!response.ok) {
      switch (response.status) {
        case 404:
          setErrorMessage(t('forgotPassword.emailNotFound'));
          break;

        default:
          setErrorMessage(t('errors.unknown', { code: response.status || 1 }));
          break;
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`${t('forgotPassword.title')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>
      <div className="container center">
        <div className="main-box main-box-small">
          <h1 className="text-center">{t('forgotPassword.title')}</h1>
          <form
            className="flex flex-col p-5"
            onSubmit={handleSubmit(resetPassword)}
          >
            <Input
              {...register('email')}
              id="email"
              label={t('forms.email')}
              type="email"
              required
              autofocus
            />
            <button
              className="btn btn-primary my-4"
              disabled={loading || success}
            >
              {t('actions.submit')}
            </button>
            {success && (
              <Alert variant="success">{t('forgotPassword.success')}</Alert>
            )}
            {error && <Alert variant="danger">{errorMessage}</Alert>}
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
