import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/organisms/Footer';
import Authorize from './pages/Authorize';
import Contact from './pages/Contact';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';

const App: FC = () => (
  <div className="flex flex-col min-h-screen">
    <main className="flex flex-grow">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="authorize" element={<Authorize />} />
        <Route path="contact" element={<Contact />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </main>
    <Footer />
  </div>
);
export default App;
