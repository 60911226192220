import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Contact: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {`${t('contact.title')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>
      <div className="container center">
        <main className="main-box main-box-small">
          <h1 className="text-center">{t('contact.title')}</h1>

          <dl>
            <dt>{t('contact.address')}</dt>
            <dd>{t('contact.addressValue')}</dd>
            <dt>{t('contact.email')}</dt>
            <dd>
              <a href="mailto:aifa@aifa.com.tw">aifa@aifa.com.tw</a>
            </dd>
            <dt>{t('contact.phone')}</dt>
            <dd>
              TEL: 886-7-7777128
              <br />
              FAX: 886-7-7777407
            </dd>
            <dt>{t('contact.serviceHours')}</dt>
            <dd>{t('contact.serviceHoursValue')}</dd>
          </dl>
        </main>
      </div>
    </>
  );
};

export default Contact;
