import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useFetch from 'use-http';
import Alert from '../../components/atoms/Alert';
import AuthorizeForm, {
  AuthorizeFormProps,
} from '../../components/molecules/AuthorizeForm';

const Authorize: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [clientName, setClientName] = useState<string>();
  const [authorizeParams, setAuthorizeParams] = useState<AuthorizeFormProps>();
  const { error, get, response } = useFetch();
  const errorMessage = useMemo(() => {
    if (!authorizeParams) {
      return t('authorize.invalidParameters');
    }

    switch (response.status) {
      case 401:
        return t('errors.invalidCredentials');

      default:
        return t('errors.unknown', { code: error });
    }
  }, [authorizeParams, error, response, t]);

  useEffect(() => {
    let isMounted = true;
    const queryParams = new URLSearchParams(search);

    if (
      !(
        queryParams.has('client_id') &&
        queryParams.has('redirect_uri') &&
        queryParams.has('state')
      )
    ) {
      return;
    }

    setAuthorizeParams({
      clientId: queryParams.get('client_id') as string,
      redirectUri: queryParams.get('redirect_uri') as string,
      state: queryParams.get('state') as string,
    });

    const getClientName = async () => {
      const body = await get(
        `/oauth2/client/${queryParams.get('client_id') as string}`
      );

      if (isMounted) {
        setClientName(body?.name);
      }
    };

    getClientName();

    return () => {
      isMounted = false;
    };
  }, [get, search]);

  return (
    <>
      <Helmet>
        <title>
          {`${t('authorize.title')} - ${process.env.REACT_APP_TITLE}`}
        </title>
      </Helmet>

      <div className="container center">
        <div className="main-box main-box-small">
          <h1 className="text-center">{t('authorize.title')}</h1>
          {authorizeParams && clientName && (
            <>
              <p data-testid="permission-request">
                <Trans
                  i18nKey="authorize.permissionRequest"
                  values={{ clientName }}
                  components={{ strong: <strong /> }}
                >
                  <strong />
                </Trans>
              </p>
              <AuthorizeForm
                clientId={authorizeParams.clientId}
                redirectUri={authorizeParams.redirectUri}
                state={authorizeParams.state}
              />
            </>
          )}
          {(!Boolean(authorizeParams) || error) && (
            <Alert variant="danger">{errorMessage}</Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default Authorize;
